import { environment } from './environments/environment';
import { IConfig, IBaseConfig } from './app/providers/services/config/config.interface';

const configPrefix = environment?.config || '';
let appConfig: IConfig;
let configRemotes: IBaseConfig[];

/**
 * Fetches the configuration file based on the environment.
 * @returns {Promise<IConfig>} - The application configuration.
 */
async function fetchAppConfig(): Promise<IConfig> {
  const response = await fetch(`./assets/config/app-config${configPrefix}.json`);
  if (!response.ok) {
    throw new Error(`Failed to fetch config: ${response.statusText}`);
  }
  return response.json();
}

/**
 * Initializes the application with the fetched configuration.
 * @param {IConfig} config - The application configuration.
 */
async function initializeApp(config: IConfig): Promise<void> {
  try {
    const bootstrapModule = await import('./bootstrap');
    await bootstrapModule.init(config);
  } catch (error) {
    console.error('Error initializing the app:', error);
  }
}

/**
 * Main function to fetch configuration and initialize the application.
 */
async function main(): Promise<void> {
  try {
    appConfig = await fetchAppConfig();
    configRemotes = appConfig?.remotes || [];
    await initializeApp(appConfig);
  } catch (error) {
    console.error('Error during app initialization:', error);
  }
}

// Start the app initialization process.
main();
